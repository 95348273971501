<template>
    <usuario-form
        acao="ATUALIZAR"
        @salvar="atualizar($event)"
        :cancelar="cancelar"
        :erros="erros"
        :usuario="usuario"
    ></usuario-form>
</template>

<script>
import UsuariosServices from './services';
import UsuarioForm from './UsuarioForm';

export default {
    components: {
        UsuarioForm,
    },

    data() {
        return {
            erros: [],
            usuario: null,
            atualizado: false,
        };
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Usuarios_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterUsuario() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorId(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(usuarioAtualizado) {
            this.$store.dispatch('addRequest');
            let usuarioAtualizadoDto = {
                usuarioId: this.$route.params.id,
                tipo: usuarioAtualizado.tipo,
                tipoPessoa: usuarioAtualizado.tipoPessoa,
                nome: usuarioAtualizado.nome,
                login: usuarioAtualizado.login,
                email: usuarioAtualizado.email,
                telefone: usuarioAtualizado.telefone,
            };
            UsuariosServices.atualizar(usuarioAtualizadoDto).then(response => {
                if (response && response.success) {
                    this.usuario = response.data;
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Usuário atualizado com sucesso',
                        life: 3000,
                    });
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterUsuario();
    },
};
</script>
